import { Routes, Route } from "react-router-dom";
import { Layout } from "./Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchFilterData, getCards } from "../redux/slices/filterSlice";
import { CartPage } from "../pages/Cart";
import { HomePage } from "../pages/Home";
import { CV } from "./CV";
import { NothingFoundPage } from "../pages/404";

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFilterData());
    dispatch(getCards())
  }, []);

  console.log('render App')

  return (
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route path=':slug' element={<CV/>}/>
          <Route index element={<HomePage/>}/>
          <Route path='cart' element={<CartPage/>}/>
          <Route path='404' element={<NothingFoundPage/>}/>
        </Route>
      </Routes>
  );
}

export default App;
