// import {useEffect, useState} from "react";
// import stl from './cv.module.css'
//
// function Experiense({data, dateFormatting}) {
//
//     const [expItems, setExpItems] = useState(data)
//     useEffect(() => {
//         setExpItems(Object.values(data).map(i => {
//             return i.end_date_unix
//                 ? {
//                     ...i,
//                     end_date_unix: dateFormatting(i.end_date_unix),
//                     start_date_unix: dateFormatting(i.start_date_unix)
//                 }
//                 : {...i, end_date_unix: 'Present', start_date_unix: dateFormatting(i.start_date_unix)}
//         }))
//     }, [data])
//
//     return (
//         <div className={stl.expWrapper}>
//             <span>work experience</span>
//             <div className={stl.expContainer}>
//                 {expItems
//                     // .sort((a, b) => b.end_date_unix - a.end_date_unix)
//                     .map((item, index) => {
//                         return (
//                             <article className={stl.expItem} key={index}>
//                                 <span className={stl.expItem_name}>
//                                 <b>{item.company_name}</b>
//                                 <i>
//                                     {item.start_date_unix} - {item.end_date_unix}
//                                 </i>
//                             </span>
//                                 <span className={stl.expItem_position}>
//                                 <b>{`Position: `}</b> {item.position}
//                             </span>
//                                 <p className={stl.expItem_description}>
//                                     {item.responsibilities_text}
//                                 </p>
//                                 {item.responsibilities.length !== 0 && (<span className={stl.expItem_resps}>
//                                 {`Position responsibilities: `}
//                                     {item.responsibilities.map((item, index) => <i key={index}>{item}</i>)}
//                             </span>)}
//                             </article>
//                         )
//                     })
//                 }
//             </div>
//         </div>
//     )
// }
//
// export {Experiense}


// const Experience_obj = { TODO: what fields are required?
//     company_name: 'Commodity Exchange "Donetsk Universal Commodity Exchange"',
//     position: 'computing center technician',
//     start_date: '2003-01-01T00:00:00.000000Z',  TODO: Delete this row
//     end_date: '2007-01-01T00:00:00.000000Z',  TODO: Delete this row
//     responsibilities: [ TODO: need to understand what will be better - empty array or null ----null
//         'data collection',
//         'data selection',
//         'manage databases',
//         'recover database'
//     ],
//     experience_tags: [ TODO: need to understand what will be better - empty array or null ----null
//         'Commodity science ',
//         'IT, computers & Internet'
//     ],
//     responsibilities_text: 'Maintenance and technical support of servers and workstations of the exchange, network equipment and internal networks, Digitization, processing and updating of large data sets', TODO: what about formatting there?
//     start_date_unix: 1041379200, TODO: rename and send [unix*1000]
//     end_date_unix: 1167609600 TODO: rename and send [unix*1000]
// }

import {
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey } from "@mui/material/colors";

export default function Experience({ item }) {
  const [open, setOpen] = useState(false);
  const { company_name, position, responsibilities_text } = item;
  const startDate = new Date(item.start_date).toLocaleDateString("default", {
    year: "numeric",
    month: "2-digit",
  });
  const endDate = item.end_date
      ? new Date(item.end_date).toLocaleDateString("default", {
        year: "numeric",
        month: "2-digit",
      })
      : "Present";
  return (
      <>
        <ListItemButton onClick={() => setOpen(!open)} selected={open}>
          <ListItemIcon>
            <BusinessIcon/>
          </ListItemIcon>
          <ListItemText
              primaryTypographyProps={{
                component: "div",
              }}
              primary={
                <Typography
                    sx={{
                      // fontFamily: "unset",
                      // fontWeight: "600",
                      fontVariationSettings: `'wdth' 80, 'wght' 600`,
                    }}>
                  {company_name}
                </Typography>
              }
              secondaryTypographyProps={{
                component: "div",
              }}
              secondary={
                <Stack
                    sx={{
                      flexFlow: "row nowrap",
                      gap: "1rem",
                    }}>
                  <Stack
                      sx={{
                        flexFlow: "row wrap",
                        alignItems: "center",
                        gap: ".3rem 1rem",
                      }}>
                    {position}
                    <Chip
                        icon={<AccessTimeIcon/>}
                        label={`${startDate} - ${endDate}`}
                        size={"small"}
                        variant="filled"
                        sx={{
                          backgroundColor: grey[50],
                        }}
                    />
                  </Stack>
                </Stack>
              }
          />
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemText
                primaryTypographyProps={{
                  component: "div",
                }}
                secondaryTypographyProps={{
                  component: "div",
                }}
                primary={
                  <Typography variant={"body2"}>{responsibilities_text}</Typography>
                }
                secondary={
                  <Stack
                      sx={{
                        flexFlow: "row wrap",
                        gap: "1rem",
                        padding: ".5rem 0",
                      }}>
                    {item.experience_tags.map((tag) => (
                        <Chip label={tag} key={tag} size={"small"}/>
                    ))}
                  </Stack>
                }
                sx={{
                  padding: "1rem",
                }}
            />
          </List>
        </Collapse>
      </>
  );
}
