import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip, Paper,
  Skeleton,
  Stack,
} from "@mui/material";

const globalGap = "2.4dvw";

const SkeletonCv = () => {
  return (
      <Stack sx={{
        backgroundColor: "transparent",
        paddingX: '1rem',
        gap: globalGap,
        display: "flex",
        flexFlow: "row wrap",
        paddingTop: '3rem',
        paddingBottom: '70px',
        maxWidth: '90rem',
      }}>

        {/*////////////////////Left Side//////////////////////////*/}

        <Box sx={{
          display: "flex",
          minHeight: '10rem',
          minWidth: '280px',
          width: '16%',
          flexGrow: 1,
          flexDirection: 'column',
          gap: globalGap,
        }}>

          <Box
              sx={{
                display: "flex",
              }}>
            <Card
                sx={{
                  flex: 1,
                  minWidth: "15rem",
                  flexShrink: "1",
                  width: "27%",
                }}>
              <Box sx={{
                aspectRatio: 1,
                display: "flex",
              }}>
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"/>
              </Box>
              <CardContent>
                <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "0.3rem"
                    }}>
                  <Skeleton component={Chip} variant="chip"
                            width={100} height={30}/>
                  <Skeleton component={Chip} variant="chip"
                            width={70} height={32}
                  />
                </Box>
                <Box>
                  <Skeleton component={Chip} variant="chip"
                            width={150} height={16}/>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Card>
            <Skeleton variant="rectangular"
                      width={'100%'} height={47}
            />

            <Box
                sx={{
                  p: 3,
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  alignItems: 'center',
                }}
            >
              <Skeleton variant="text" width={120} height={70}/>
              <Skeleton variant="text" width={120} height={20}/>
              <Skeleton variant="text" width={80} height={15}/>
            </Box>

            <Skeleton variant="rectangular"
                      width={'100%'} height={47}
            />
          </Card>

          {/*////////////////////Languages//////////////////////////*/}

          <Box display="flex" sx={{
            flexDirection: "row",
          }}>
            <Paper
                sx={{
                  flexGrow: 1,
                  minWidth: "280px",
                  display: "flex",
                  flexFlow: "column nowrap",
                  overflow: "hidden",
                }}>
              <Skeleton variant="rectangular"
                        width={'100%'} height={47}
              />
              <Stack sx={{
                padding: '1rem',
                gap: '1rem',
                flexFlow: 'row wrap'
              }}>
                <Skeleton component={Chip} variant="chip"
                          width={76} height={20}
                />
                <Skeleton component={Chip} variant="chip"
                          width={52} height={20}
                />
                <Skeleton component={Chip} variant="chip"
                          width={45} height={20}
                />
                <Skeleton component={Chip} variant="chip"
                          width={70} height={20}
                />
                <Skeleton component={Chip} variant="chip"
                          width={50} height={20}
                />
                <Skeleton component={Chip} variant="chip"
                          width={62} height={20}
                />
              </Stack>
            </Paper>
          </Box>

          {/*////////////////////Languages//////////////////////////*/}

          <Box display="flex" sx={{ flexDirection: "row", gap: globalGap, flexWrap: "wrap" }}>
            <Paper
                sx={{
                  width: "calc(48% - 1rem)",
                  flexGrow: 1,
                  minWidth: "280px",
                  display: "flex",
                  flexFlow: "column nowrap",
                  overflow: "hidden",
                }}>
              <Skeleton variant="rectangular"
                        width={'100%'} height={47}
              />
              <Stack sx={{
                padding: '1rem',
                gap: '1rem',
                flexFlow: 'row wrap'
              }}>
                {Array.from(new Array(3)).map((item, index) => (
                    <Skeleton component={Chip}
                              key={index}
                              variant="chip"
                              sx={{
                                flexGrow: 1,
                                minWidth: '20rem',
                                height: '32px'
                              }}
                    />
                ))}
              </Stack>
            </Paper>
          </Box>

        </Box>

        {/*////////////////////Right Side//////////////////////////*/}

        <Box sx={{
          display: "flex",
          minHeight: '40rem',
          minWidth: '15rem',
          width: '53%',
          flexGrow: '1',
          flexDirection: 'column',
          gap: globalGap,
        }}>

          {/*////////////////////Video player//////////////////////////*/}

          <Paper
              sx={{
                display: "flex",
                minWidth: "67%",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "10rem",
                aspectRatio: "16 / 9",
                overflow: 'hidden'
              }}>
            <Skeleton variant="rectangular"
                      width={'100%'} height={'100%'}
            />

          </Paper>

          {/*////////////////////EXPERIENCE & Education//////////////////////////*/}

          {Array.from(new Array(2)).map((item, index) => (
              <Box key={index} display="flex" sx={{ flexDirection: "row", gap: globalGap, flexWrap: "wrap" }}>
                <Card sx={{
                  flexGrow: 1,
                  minWidth: "280px",
                  display: "flex",
                  flexFlow: "column nowrap",
                  overflow: "hidden",
                }}>
                  <Skeleton variant="rectangular"
                            width={'100%'} height={47}
                  />

                  <CardContent
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "flex-start",
                        flexDirection: 'column'
                      }}
                  >
                    {Array.from(new Array(3)).map((item, index) => (
                        <Stack component={"section"}
                               key={index}
                               sx={{
                                 display: "flex",
                                 flexFlow: "row nowrap",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 gap: "1rem",
                               }}
                        >
                          <Skeleton
                              variant="circular"
                              sx={{
                                width: '2rem',
                                height: '2rem',

                              }}
                          />
                          <Stack
                              component={"section"}
                              flexGrow={1}
                              sx={{
                                justifyContent: "center",
                                gap: ".5rem",
                              }}
                          >
                            <Box sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}>

                              <Skeleton component={Chip} variant="chip"
                                        width={136} height={26}
                              />
                            </Box>
                            <Skeleton variant="text"
                                      sx={{
                                        flexGrow: 1,
                                        minWidth: '6rem',
                                        height: '18px'
                                      }}
                            />
                          </Stack>
                        </Stack>
                    ))}
                  </CardContent>
                </Card>
              </Box>
          ))}
        </Box>
      </Stack>
  );
};

export default SkeletonCv;
