import { configureStore } from "@reduxjs/toolkit";
import filterSlice from "./slices/filterSlice";
import cartSlice from "./slices/cartSlice";
// import formSlice from "./slices/formSlice";
// import buttonSlice from "./slices/buttonSlice"

export const store = configureStore({
  reducer: {
    filterSlice,
    cartSlice,
    // formSlice,
    // buttonSlice,
  },
});
