import { AppSettings } from "../../customization";

function Logo({ size }) {

  const {style} = AppSettings

  return <img
      style={{
        minWidth: "280px",
        width: size === 's' ? '30%' : '90%',
      }}
      src={style.main.logo}
      alt={'logo-full'}
  />
}

export { Logo }




