import { Stack } from "@mui/material";
import SpaLink from "../components/SpaLink/SpaLink";
import { Logo } from "../components/Logo";
import { AppSettings } from "../customization";
import { getContrastColor } from "../lib/helpers";

function NothingFoundPage() {
  const { style } = AppSettings

  return (
      <Stack sx={{
        width: '100%',
        flexGrow: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column nowrap',
        gap: '3rem',
        alignSelf: 'center',
        height: 'calc(100vh - 6rem)'
      }}>
        <Logo size={'s'}/>

        <p style={{
          color: getContrastColor(style.main.backgroundColor)
        }}>
          Nothing found :( Try again starting {" "}
          <SpaLink
              to={"/"}
              sx={{
                display: "inline",
                color: getContrastColor(style.main.backgroundColor),
                textDecoration: "underline",
                cursor: "pointer",
              }}>
            Homepage
          </SpaLink>
        </p>
      </Stack>
  )
}

export { NothingFoundPage }
