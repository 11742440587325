import ReactPlayer from 'react-player'

export default function Player({ url }) {
  return <ReactPlayer
      width={'100%'}
      height={'100%'}
      controls={true}
      style={{ aspectRatio: '16 / 9' }}
      url={url}
  />
}
