import {
  setDepartmentIds,
  setDomainType,
  setIndustriesIds,
  setLangIds,
  setPositionIds,
  setRate,
  setSkillIds,
} from "../redux/slices/filterSlice";

// Array to match select and values ​​and methods in store
export const storeIDs = [
  {
    id: "deps",
    filterAction: setDepartmentIds, // data entry method for filtering employees in the store
    value: "department_ids", // key to access data in store
    placeholder: "Departments",
    type: "select",
  },
  {
    id: "pos",
    filterAction: setPositionIds,
    value: "position_ids",
    placeholder: "Positions",
    type: "select",
  },
  {
    id: "skills",
    filterAction: setSkillIds,
    value: "skill_ids",
    placeholder: "Skills",
    type: "select",
  },
  {
    id: "langs",
    filterAction: setLangIds,
    value: "lang_ids",
    placeholder: "Languages",
    type: "select",
  },
  {
    id: "industries",
    filterAction: setIndustriesIds,
    value: "industries_ids",
    placeholder: "Industry experience",
    type: "select",
  },
  {
    id: "domain_type",
    filterAction: setDomainType,
    value: "domain_type",
    type: "radio",
  },
  {
    id: "rate",
    filterAction: setRate,
    value: "rate",
    type: "radio",
  },
];
