import dayjs from 'dayjs';

export const checkReselect = (employerRate, employeeRate) => {
  switch (employerRate) {
    case 'minimal':
      return !(Number(employeeRate) >= 0.25);
    case 'part-time':
      return !(Number(employeeRate) >= 0.5);
    case 'full-time':
      return !(Number(employeeRate) >= 1);
    default:
      return false;
  }
};

export const generateEmployeeStatus = (currentRate) => {
  const rateStatusMap = {
    0: 'the employee is unavailable',
    0.25: 'available for minimal employment',
    0.5: 'available for part-time or less',
    0.75: 'available for part-time or less',
  };
  return typeof currentRate !== 'string'
    ? 'this employee is not available'
    : rateStatusMap[currentRate] || 'available for full-time or less';
};

export const getLang = () => {
  if (typeof window === 'undefined') return 'en';
  if (navigator.languages !== undefined) return navigator.languages[0];
  else return navigator.language;
};

export const getTimeDifference = (city) => {
  const kyivDate = new Date().toLocaleString('en-US', { timeZone: 'Europe/Kyiv' });
  const cityDate = new Date().toLocaleString('en-US', { timeZone: city });
  const differenceMillis = new Date(cityDate) - new Date(kyivDate);
  const hoursDifference = differenceMillis / (1000 * 60 * 60);
  return hoursDifference;
};

export const setDefaultDate = (addedTime) => {
  const date = new Date();
  const mins = date.getMinutes();
  const diff = 10 - (mins % 10);
  date.setMinutes(mins + diff + addedTime);
  return dayjs(date.getTime());
};
