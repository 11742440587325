// Returns max rate for RateSlider
export function getMaxRate(cards) {
  let value = 0;
  for (let card of cards) {
    if (!card.sum_rate_rh) continue;
    const nextValue = +card.sum_rate_rh;
    if (nextValue > value) value = nextValue;
    if (value >= 1) break;
  }
  if (value < 0.5) return 0;
  if (value >= 0.5 && value < 1) return 1;
  if (value >= 1) return 2;
}

export function getContrastColor(hexColor) {
  // Відкидаємо символ `#` і отримуємо три компоненти кольору
  const hex = hexColor.slice(1);
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Формула для визначення контрастного кольору
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness >= 128 ? '#5a5a5a' : 'white';
}
