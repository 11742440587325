import { Box, FormLabel, Slider, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { storeIDs } from "../../../../lib/mapData";
import { AppSettings } from "../../../../customization";

function valueLabelFormat(value) {
  const possibleValues = ['40h+', '80h+', '160h+'];
  return possibleValues[value];
}

function valueSliceFormat(value) {
  const possibleValues = ['any', 'part', 'full'];
  return possibleValues[value];
}

const marks = [
  {
    value: 0,
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
];

const { style } = AppSettings

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(() => ({
  color: style.primaryColor,
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: style.primaryColor,
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.6)',
    boxShadow: iOSBoxShadow,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

export default function RateSlider({ id }) {
  const dispatch = useDispatch();
  const { maxRate, isFiltered } = useSelector(state => state.filterSlice);

  const [value, setValue] = useState(0);
  const initialRef = useRef(null);

  useEffect(() => {
    if (initialRef.current) {
      const action = storeIDs.find((a) => a.id === id).filterAction;
      const formattedValue = valueSliceFormat(value);
      dispatch(action(formattedValue));
    } else {
      initialRef.current = true;
    }
  }, [dispatch, id, value]);

  const handleChange = (_, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (!isFiltered) {
      setValue(0);
    }
  }, [isFiltered]);

  return (
    <>
      <FormLabel>Availability per month: {valueLabelFormat(value)}</FormLabel>
      <Box sx={{ padding: '0 .8rem' }}>
        <IOSSlider
          value={value}
          min={0}
          step={1}
          max={maxRate}
          onChange={handleChange}
          marks={marks}
          valueLabelDisplay='auto'
          valueLabelFormat={(v) => valueLabelFormat(v)}
        />
      </Box>
    </>
  );
}
