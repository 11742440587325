import { Stack } from '@mui/material';
import { motion } from 'framer-motion';
import { AppSettings } from "../../customization";
export function AnimateGradientBg({ children }) {
  const { style } = AppSettings
  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      minWidth={'25rem'}
      width={'50%'}
      padding={'5dvh 4dvh'}
      flexGrow={1}
      component={motion.div}
      transition={{
        repeat: Infinity,
        duration: 10,
        ease: [0.67, 0.67, 0.67, 0.67],
      }}
      initial={{ background: `linear-gradient(-180deg, ${style.main.primaryColor} 0%, ${style.main.secondaryColor} 100%)` }}
      animate={{
        background: `linear-gradient(180deg, ${style.main.primaryColor} 0%, ${style.main.secondaryColor} 100%)`,
      }}
      exit={{ background: `linear-gradient(0deg, ${style.main.primaryColor} 0%, ${style.main.secondaryColor} 100%)` }}
      sx={{
        boxShadow: '0 0 .2rem 0 rgba(0,0,0,0.2)',
      }}
    >
      {children}
    </Stack>
  );
}
