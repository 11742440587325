import { createTheme } from '@mui/material/styles';
import { AppSettings } from "../../customization";
import { getContrastColor } from "../helpers";

const { style } = AppSettings

const theme = createTheme({
  palette: {
    background: {
      default: style.main.backgroundColor || "rgb(249,251,253)",
    },
    primary: {
      main: style.main.primaryColor,
    },
    secondary: {
      main: style.main.secondaryColor,
    },
  },
  typography: {
    fontFamily: 'OpenSans-VariableFont, sans-serif',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: style.main.borderRadius,
          boxShadow: style.main.boxShadow,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        groupedText: {
          borderColor: '#f1f1f1',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: style.main.borderRadius,
          boxShadow: style.main.boxShadow,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiToggleButton-root.Mui-selected': {
            color: style.main.primaryColor,
            borderColor: getContrastColor(style.main.backgroundColor),
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: style.footer.BtnBGColor || style.main.primaryColor,
            color: getContrastColor(style.footer.BtnBGColor),
          },
        }
      },
    },
  },
});
export default theme;
